import { memo, ReactNode } from 'react';
import { Snackbar, SnackbarProps, Alert, AlertTitle, Typography } from '@mui/material';
import { AlertProps } from '@mui/lab';
import { randomNum } from '@src/utils/utils';
import { styled } from '@mui/material/styles';
import globalConfig from '../../config';
import { TOAST_ICONS } from '@src/constants/common';
import COLORS from '@src/constants/colors';
const {
  DEFAULT_TOAST_DURATION
} = globalConfig;

/**
 * Toast - For creating a Snackbar-toast.
 * NOTE: Use along with the useToast hook available at 'hooks/useToast
 */
export type ToastType =
/**
 * auto: Hides automatically
 */
'auto'
/**
 *  closable: Hides when the 'close' action is performed
 */ | 'closable'
/**
 * persistent: Does not hide
 */ | 'persistent';
type CustomAlertProps = {
  variants?: 'success' | 'info' | 'warning' | 'error' | 'default';
} & AlertProps;
export type ToastAttributes = {
  /**
   * Snackbar open state, please use 'persist' props instead 'open' props. this props will be deleted soon
   */
  open?: boolean;
  /**
   * The Snackbar content - this can be a string or any React node/element
   */
  message: any;
  /**
   * For specifying the toast type
   */
  type?: ToastType;
  /**
   * The toast state setter derived from the 1 index of the useToast hook call.
   */
  setToast?: (toast: {
    open: boolean;
    message: any;
    type?: ToastType;
  } & SnackbarProps & AlertProps) => void;
  /**
   * use snackbar? (for support documentation only)
   */
  alertTitle?: string;
  /**
   * use snackbar? (for support documentation only)
   */
  snackbar?: boolean;
  /**
   * to make it static and not closeable
   */
  persist?: boolean;
  /**
   * to customize the close action icon
   */
  actionIcon?: ReactNode;
  onClosedHandler?: () => void;
} & SnackbarProps & CustomAlertProps;
export const StyledAlert = styled(Alert, {
  shouldForwardProp: props => props !== 'variants'
})<CustomAlertProps>(({
  variants
}) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  background: COLORS.slate[700],
  ...(variants === 'success' && {
    color: COLORS.green[400]
  }),
  ...(variants === 'error' && {
    color: COLORS.red[500]
  }),
  ...(variants === 'info' && {
    color: COLORS.blue[400]
  }),
  ...(variants === 'warning' && {
    color: COLORS.amber[400]
  })
}));
const Toast: React.FC<ToastAttributes> = memo<ToastAttributes>(_props => {
  const props = {
    ..._props
  };
  const {
    open,
    message,
    setToast,
    onClosedHandler,
    snackbar = true,
    alertTitle,
    severity = 'success',
    autoHideDuration,
    anchorOrigin = {
      horizontal: 'center',
      vertical: 'bottom'
    },
    id
  } = props;
  const alertOnClose = () => {
    if (setToast) setToast({
      open: false,
      message: null
    });
    if (onClosedHandler) onClosedHandler();
  };
  const snackbarOnClose = (_event, reason) => {
    // Stop propogation on external click
    if (reason === 'clickaway') {
      return;
    }
    if (onClosedHandler) onClosedHandler();
  };
  const ShowSnackbar = <>
      <Snackbar key={randomNum(1).toString()} onClose={snackbarOnClose} open={open} TransitionComponent={props.TransitionComponent} autoHideDuration={autoHideDuration || DEFAULT_TOAST_DURATION} anchorOrigin={anchorOrigin} id={`snackbar-${id}-${props.variant}`} data-testid={`snackbar-${id}-${props.variant}`}>
        <StyledAlert action={props.action} variants={severity} icon={props.icon || TOAST_ICONS[severity]} variant={props.variant} onClose={alertOnClose} sx={{
        background: 'black'
      }} id={`alert-${id}-${props.variant}`} data-testid={`alert-${id}-${props.variant}`}>
          {alertTitle && <AlertTitle id={`alert-title-${id}`}>{alertTitle}</AlertTitle>}
          <Typography id={`alert-msg-${id}`} variant="body2">
            {message}
          </Typography>
        </StyledAlert>
      </Snackbar>
    </>;
  const ShowStyledAlert = <>
      {open ? <StyledAlert action={props.action} variants={props.severity} icon={props.icon} variant={props.variant} onClose={alertOnClose} sx={{
      background: 'black'
    }} id={`alert-${id}-${props.variant}`} data-testid={`alert-${id}-${props.variant}`}>
          {alertTitle && <AlertTitle id={`alert-title-${id}`}>{alertTitle}</AlertTitle>}
          <Typography id={`alert-msg-${id}`} variant="body2">
            {message}
          </Typography>
        </StyledAlert> : null}
    </>;
  return <>{snackbar ? ShowSnackbar : ShowStyledAlert}</>;
});
export default Toast;