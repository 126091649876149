import { forwardRef, useCallback, ReactNode } from 'react';
import { useSnackbar, SnackbarContent, CustomContentProps } from 'notistack';
import { AlertTitle, Typography } from '@mui/material';
import { StyledAlert } from './Toast';
import { TOAST_ICONS, TOAST_TITLE } from '@src/constants/common';
interface CustomSnackbarProps extends CustomContentProps {
  title?: string;
  actionIcon?: ReactNode;
}
const CustomSnackbar = forwardRef<HTMLDivElement, CustomSnackbarProps>((props, ref) => {
  const {
    id,
    message,
    variant = 'success',
    title,
    actionIcon
  } = props;
  const {
    closeSnackbar
  } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);
  return <SnackbarContent ref={ref}>
        <StyledAlert action={actionIcon} variants={variant} icon={TOAST_ICONS[variant]} onClose={handleDismiss} sx={{
      background: 'black'
    }} data-testid={`alert-${variant}`}>
          <AlertTitle id={`alert-title-${variant}`}>
            {title || TOAST_TITLE[variant]}
          </AlertTitle>

          <Typography id={`alert-msg-${variant}`} variant="body2">
            {message}
          </Typography>
        </StyledAlert>
      </SnackbarContent>;
});
export default CustomSnackbar;