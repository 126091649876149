'use client';

import { GOOGLE_TAG_MANAGER_ID } from '@/config';
import * as Sentry from '@sentry/nextjs';
import { CssBaseline } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useFirebaseRemoteConfig from '@src/hooks/useFirebaseRemoteConfig';
import useMainStore from '@src/stores/main';
import useUserStore from '@src/stores/user';
import { hasEmptyOrNullValue, pushDataToDataLayer } from '@src/utils/common';
import { firebaseConfig } from '@src/utils/firebase-config';
import { ReactNode, useEffect } from 'react';
import theme from '../theme';
import TagManager from 'react-gtm-module';
import { SnackbarProvider } from 'notistack';
import CustomSnackbar from '@components/toast/CustomSnackbar';
const classes = {
  root: 'flex flex-grow  w-full bg-slate-900 text-white'
};
const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID
};
const MainProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const {
    init
  } = useFirebaseRemoteConfig();

  // NB: Global state value of user data.
  const userState = useUserStore(state => state.user);
  const setFBRCLoader = useMainStore(state => state.setFBRCLoader);
  const handleFBRCInitialisation = async () => {
    setFBRCLoader(true);
    if (!hasEmptyOrNullValue(firebaseConfig)) {
      await init().catch(() => undefined);
    }
    setFBRCLoader(false);
  };
  useEffect(() => {
    if (userState) {
      Sentry.setUser({
        email: userState?.email
      });
      if (window?.dataLayer) {
        pushDataToDataLayer({
          event: 'userDetails',
          userId: userState.id
        });
      }
    }
  }, [JSON.stringify(userState)]);
  useEffect(() => {
    handleFBRCInitialisation();

    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // GTM related changes
    if (GOOGLE_TAG_MANAGER_ID) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);
  return <>
      <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="MainProvider.tsx" />
      <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-source-file="MainProvider.tsx">
        <StyledEngineProvider injectFirst data-sentry-element="StyledEngineProvider" data-sentry-source-file="MainProvider.tsx">
          <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="MainProvider.tsx">
            <SnackbarProvider Components={{
            default: CustomSnackbar,
            success: CustomSnackbar,
            error: CustomSnackbar,
            warning: CustomSnackbar,
            info: CustomSnackbar
          }} anchorOrigin={{
            horizontal: 'center',
            vertical: 'bottom'
          }} maxSnack={3} data-sentry-element="SnackbarProvider" data-sentry-source-file="MainProvider.tsx">
              <div className={classes.root}>{children}</div>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppRouterCacheProvider>
    </>;
};
export default MainProvider;